export const contentSourcesEntitySchemaURI =
  "iglu:com.rome2rio/content_sources/jsonschema/1-0-0";

export const eventTriggerEntitySchemaURI =
  "iglu:com.rome2rio/event_trigger/jsonschema/1-0-0";

export const experimentEntitySchemaURI =
  "iglu:com.rome2rio/experiments/jsonschema/1-0-0";

export const extraInfoEntitySchemaURI =
  "iglu:com.rome2rio/extra_info/jsonschema/1-0-0";

export const requestIdentifiersEntitySchemaURI =
  "iglu:com.rome2rio/request_identifiers/jsonschema/1-0-0";

export const windowIdentifiersEntitySchemaURI =
  "iglu:com.rome2rio/window_identifiers/jsonschema/1-0-0";

export const screenContentEntitySchemaURI =
  "iglu:com.rome2rio/screen_content/jsonschema/1-0-0";

export const screenLayoutEntitySchemaURI =
  "iglu:com.rome2rio/screen_layout/jsonschema/1-0-0";

export const searchEntitySchemaURI =
  "iglu:com.rome2rio/search/jsonschema/1-0-0";

export const seoStoreEntitySchemaURI =
  "iglu:com.rome2rio/seo_store/jsonschema/1-0-0";

export const serverRequestEntitySchemaURI =
  "iglu:com.rome2rio/server_request/jsonschema/1-0-0";

export type SPEventTrigger = {
  interaction: boolean;
};

export type SPRequestIdentifiers = {
  Uid: string;
  Aqid: string;
  RequestId: string;
};

export type SPWindowIdentifiers = {
  BrowserDomId: string | undefined;
  BrowserSessionId: string | undefined;
};

export type SPServerRequest = {
  R2rBotName: string | undefined;
  R2rLanguage: string | undefined;
  R2rServer: string | undefined;
  R2rPageKind: string | undefined;
};

export type SPSearchTerm = {
  Canonical: string | undefined;
  Kind: string | undefined;
  City: string | undefined;
  CountryCode: string | undefined;
  GeoCoderSource: string | undefined;
};

export type SPSearch = {
  Terms: SPSearchTerm[];
};

export type SPSeoStore = {
  Weight: number | undefined;
  FrequencyBand: string | undefined;
};

export type SPContentSources = {
  Agencies: string | undefined;
  DataSources: string | undefined;
};

type SPExperiments = {
  BackendEnrolmentsEncoded: string | undefined;
};

export type SPScreenLayout = {
  Screen: string | undefined;
  Components: string[] | undefined;
};

export type SPLocation = {
  name: string | undefined;
  kind: string | undefined;
};

export type SPScreenContent = {
  Screen: string | undefined;
  TravelKinds: string[] | undefined;
  Locations: SPLocation[] | undefined;
};

export type SPDimension = {
  Key: string; // maxLength: 200
  Value: string | undefined; // maxLength: 500
};

export type SPMetric = {
  Key: string; // maxLength: 200
  Value: number | undefined;
};

export type SPExtraInfo = {
  Dimensions: SPDimension[];
  Metrics: SPMetric[];
};

export type SPSchemaMapping = {
  [contentSourcesEntitySchemaURI]: SPContentSources;
  [eventTriggerEntitySchemaURI]: SPEventTrigger;
  [experimentEntitySchemaURI]: SPExperiments;
  [extraInfoEntitySchemaURI]: SPExtraInfo;
  [requestIdentifiersEntitySchemaURI]: SPRequestIdentifiers;
  [windowIdentifiersEntitySchemaURI]: SPWindowIdentifiers;
  [screenContentEntitySchemaURI]: SPScreenContent;
  [screenLayoutEntitySchemaURI]: SPScreenLayout;
  [searchEntitySchemaURI]: SPSearch;
  [seoStoreEntitySchemaURI]: SPSeoStore;
  [serverRequestEntitySchemaURI]: SPServerRequest;
};

export type Rome2RioEntitySchema = keyof SPSchemaMapping;

export type SnowplowEntity<
  S extends Rome2RioEntitySchema = Rome2RioEntitySchema,
> = {
  schema: S;
  data: SPSchemaMapping[S];
};

/**
 * Allows attaching arbitrary key-value pairs to a snowplow event.
 * Dimensions are strings and metrics are numbers.
 *
 * Passing an array as a value will result in multiple key-value pairs, whilst passing null or undefined will result in no key-value pair.
 *
 * ```
 * { "transport": ["plane", "bus"] } => [{ Key: "transport", Value: "plane" }, { Key: "transport", Value: "bus" }]
 * { "transport": undefined } => []
 * ```
 */
export type ExtraInfoProps = {
  dimensions?: Record<string, string | string[] | undefined | null>;
  metrics?: Record<string, number | number[] | undefined | null>;
};

export function createExtraInfoContext({
  dimensions,
  metrics,
}: ExtraInfoProps) {
  return {
    schema: extraInfoEntitySchemaURI,
    data: {
      Dimensions: dimensions ? toSnowplowRecords(dimensions) : [],
      Metrics: metrics ? toSnowplowRecords(metrics) : [],
    },
  } satisfies SnowplowEntity<typeof extraInfoEntitySchemaURI>;
}

function toSnowplowRecords<T>(
  obj: Record<string, T | T[] | undefined | null>,
): { Key: string; Value: T }[] {
  return Object.entries(obj).flatMap(([Key, Value]) => {
    if (Value === undefined || Value === null) {
      return [];
    }
    if (Array.isArray(Value)) {
      return Value.map((v) => ({ Key, Value: v }));
    }
    return [{ Key, Value }];
  });
}
