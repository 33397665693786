import type { SnowplowEntity } from "./snowplowEntities";

export type SnowplowEvent = {
  category: string;
  action: string;
  label?: string;
  value?: number;
  context?: SnowplowEntity[];
};

export function sendSnowplowEvent({
  category,
  action,
  label,
  value,
  context,
}: SnowplowEvent) {
  if (window.snowplow) {
    window.snowplow("trackStructEvent", {
      category: category,
      action: action,
      label: label,
      value: value,
      context,
    });
  }
}
