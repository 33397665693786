import { featureConfigEscapeHatch } from "src/components/common-analytics/components/setup-client-features/featureConfigEscapeHatch";
import { sendSnowplowEvent } from "./snowplow/sendSnowplowEvent";
import { enqueueSnowplowEvent } from "./snowplow/enqueueSnowplowEvent";
import {
  createExtraInfoContext,
  eventTriggerEntitySchemaURI,
  type ExtraInfoProps,
  type SnowplowEntity,
} from "./snowplow/snowplowEntities";

export type AnalyticsEvent = {
  category: string;
  action: string;
  label?: string;
  value?: number;
  isNonInteraction?: boolean;
  extraInfo?: ExtraInfoProps;
};

type LogToConsole = {
  loggingTo: string;
  category: string;
  action: string;
  label?: string;
  value?: number;
  isNonInteraction?: boolean;
  context?: SnowplowEntity[];
};

// sendEvent must only ever be called from the client code b/c it relies on browser APIs & the
// analytics scripts being loaded onto the window object.
export function sendEvent({
  category,
  action,
  label,
  value,
  isNonInteraction,
  extraInfo,
}: AnalyticsEvent) {
  const snowplowIsEnabled: boolean = typeof window.snowplow === "function";

  const eventTriggerEntity = {
    schema: eventTriggerEntitySchemaURI,
    data: {
      interaction: !isNonInteraction,
    },
  } satisfies SnowplowEntity<typeof eventTriggerEntitySchemaURI>;
  const context: SnowplowEntity[] = [eventTriggerEntity];

  if (extraInfo) {
    const extraInfoContext = createExtraInfoContext(extraInfo);
    context.push(extraInfoContext);
  }

  if (featureConfigEscapeHatch.LogAnalyticsEventsToConsole) {
    logToConsole({
      loggingTo: "sp",
      category,
      action,
      label,
      value,
      isNonInteraction,
      context,
    });
  }

  if (snowplowIsEnabled) {
    const snowplowEvent = { category, action, label, value, context };

    if (featureConfigEscapeHatch.AnalyticsPostStrategy === "immediate") {
      sendSnowplowEvent(snowplowEvent);
    } else {
      enqueueSnowplowEvent(snowplowEvent);
    }
  }
}

function logToConsole({
  loggingTo,
  category,
  action,
  label,
  value,
  isNonInteraction,
  context,
}: LogToConsole) {
  const stack = new Error().stack;
  let callLine: string[] = [];
  if (stack) {
    let splitStack = stack.split("\n");

    if (splitStack[0].includes("Error")) {
      // We're on a chromium-based browser where the callstack looks something like this:
      // Error:
      //    at logToConsole (http://localhost:3000/src/analytics/sendEvent.ts?t=1705362450042:24:17)
      //    at sendEvent (http://localhost:3000/src/analytics/sendEvent.ts?t=1705362450042:14:5)
      //    at Object.triggerPageLoad (http://localhost:3000/src/sojern/SojernPixel.ts?t=1705362450042:13:5)
      //
      // We don't care about the first line, so remove it.
      splitStack = splitStack.slice(1);
    }

    // The line at the second index is the caller of sendEvent because the stack will be:
    // 0. logToConsole
    // 1. sendEvent
    // 2. caller
    const line = splitStack[2];

    // Given the line:
    // "    at Object.triggerPageLoad (http://localhost:3000/src/sojern/SojernPixel.ts?t=1705362450042:13:5)"
    //                                                                  ^
    // We're looking for the last section "SojernPixel.ts?t=1705362450042:13:5)" so that we can split it the file and line:
    // ["SojernPixel.ts?t=1705362450042", "13"]
    const callFile = line.split("/").pop() ?? "unknown:unknown";
    callLine = callFile.split(":");
  }

  console.log(
    `${loggingTo} » %c${category} %c${isNonInteraction ? "ni" : ""}%c${
      action ?? ""
    } %c${label ?? ""} %c${value ?? ""} %c[${callLine[0] + ":" + callLine[1]}]`,
    "color:blue;background: white;",
    "color:grey;border:solid 1px grey;font-size:9px;border-radius:2px;margin-inline:6px;",
    "color:green;background: white;",
    "color:#48104d;background: white;",
    "color:#999;background: white;",
    "color:#aaa;font-size:90%background: white;",
    ...(context ?? []),
  );
}
