import type { FeatureConfig } from "src/feature/FeatureConfig";

/**
 * DO NOT USE. Unless you are really really sure you need it.
 *
 * This is a FeatureConfig made available on the client globally because
 * some APIs cannot access the $global.featureConfig object that is available
 * in templates.
 *
 * Do not use this object in templates, use $global.featureConfig instead.
 */
export let featureConfigEscapeHatch: Pick<
  FeatureConfig,
  "LogAnalyticsEventsToConsole" | "AnalyticsPostStrategy"
>;
export function setFeatureConfigEscapeHatch(config: FeatureConfig) {
  featureConfigEscapeHatch = {
    LogAnalyticsEventsToConsole: config.LogAnalyticsEventsToConsole,
    AnalyticsPostStrategy: config.AnalyticsPostStrategy,
  };
}
