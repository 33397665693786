import { requestIdleCallback } from "src/utils/requestIdleCallback";
import { sendSnowplowEvent, type SnowplowEvent } from "./sendSnowplowEvent";

const pendingEvents: SnowplowEvent[] = [];

export function enqueueSnowplowEvent({
  category,
  action,
  label,
  value,
  context,
}: SnowplowEvent) {
  pendingEvents.push({
    category,
    action,
    label,
    value,
    context,
  });
  schedulePendingEvents();
}

let idleHandle: number | undefined;
function schedulePendingEvents() {
  if (idleHandle) {
    // We've already scheduled a time to send our analytics events.
    return;
  }

  idleHandle = requestIdleCallback(
    (deadline) => {
      // Reset the handle so we can re-queue in the future.
      idleHandle = undefined;

      while (deadline.timeRemaining() > 0 && pendingEvents.length > 0) {
        const next = pendingEvents.shift();
        if (!next) {
          break;
        }

        sendSnowplowEvent({
          category: next.category,
          action: next.action,
          label: next.label,
          value: next.value,
          context: next.context,
        });
      }

      if (pendingEvents.length > 0) {
        // Reschedule to send the rest of our events because we didn't get through
        // them all in the current idle period.
        schedulePendingEvents();
      }
    },
    { timeout: 2000 },
  );
}

if (!import.meta.env.SSR) {
  // Setup page state listeners so that we can flush the queue if the page
  // goes into the background. This helps us ensure we don't miss events.
  window.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      flushPendingEvents();
    }
  });

  window.addEventListener("pagehide", flushPendingEvents);
}
function flushPendingEvents() {
  while (pendingEvents.length > 0) {
    const next = pendingEvents.shift();
    if (!next) {
      break;
    }

    sendSnowplowEvent({
      category: next.category,
      action: next.action,
      label: next.label,
      value: next.value,
      context: next.context,
    });
  }
}
